import React from 'react';
import {Button} from 'components/Button';
import {useLocation} from 'react-router-dom';
import {request} from 'helpers/tools';
import {useToast} from 'helpers/hooks';

export const DetailBulkModifyButton = ({className, docs}) => {
    const location = useLocation();
    const showToast = useToast();

    const handleClick = async () => {
        if (docs.length===0){
            showToast('Vyberte doklady')
        }
        else if (docs.length===1){
            //showToast('Tohle pujde na detail')
            const detail = `${location.pathname}/${docs[0].uuid}/general`;
            window.location.href = detail
        }
        else { 
            if(docs.length>1){showToast('Zpracovávám požadavek...')}
            const url = '/get_many_details/';
            const data = {
                uuids: docs.map(doc => doc.uuid),
            };
            const response = await request(url, 'POST', JSON.stringify(data));
            
            let toggles = response.body["toggles"]
            let mergedInfos = mergeInfos(response.body["details"])
            let mergedDetail = {}
            mergedDetail["info"] = mergedInfos
            mergedDetail["history"] = []
            mergedDetail["costs"] = []
            mergedDetail["items"] = []

            
            if ( Object.keys(response.body["details"]).length===docs.length){
                sessionStorage.setItem("bulkDetailModifyUUIDS", JSON.stringify( docs.map(doc => doc.uuid)))
                sessionStorage.setItem("bulkDetailModifyData", JSON.stringify(mergedDetail))
                sessionStorage.setItem("bulkDetailModifyToggles", JSON.stringify(toggles))
                
                const detail = `${location.pathname}/modify/general`;
                window.location.href = detail
            }
        }
    }

    function mergeInfos(details) {
        const infos = Object.values(details).map(item => item.info);
        
        if (infos.length === 0) return {};
        const resultInfo = { ...infos[0] };
        for (let key in resultInfo) {
          const allSame = infos.every(info => info[key] === resultInfo[key]);
          if (!allSame) {
            resultInfo[key] = "";
          }
        }
        return resultInfo;
      }

      /*
      function mergeDocs(details) {
        const infos = Object.values(details).map(item => item.info);
        
        if (infos.length === 0) return {};
        const resultInfo = { ...infos[0] };
        for (let key in resultInfo) {
          const allSame = infos.every(info => info[key] === resultInfo[key]);
          if (!allSame) {
            resultInfo[key] = "";
          }
        }
        return resultInfo;
      }
      */
      

    return (
        <Button className={className} onClick={handleClick} style={{ display: 'inline-block', lineHeight: 'normal' }}>Upravit  doklady</Button>
    );
};

